<template>
  <div>

    <master-component
      :config="config"
      :showRemove="false"
      :showEdit="false"
      @results-search="sumTotalCC($event)"
    >
      <template v-slot:table-other-button>
        <!-- <div>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                outlined
                class="my-3"
                small
              >
                + เพิ่ม Team
              </v-btn>
            </template>
            <v-list>
              <v-list-item>
                <v-list-item-title>ทีมใหม่</v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>อ้างอิมทีมเดิม</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div> -->
        <span class="title red--text mt-8 mr-12"> {{ totalCC | currency(0) }} cc</span>

      </template>

      <template v-slot:table-isHeadTeam="{ item }">
        {{ item.isHeadTeam == true ? 'Y':''}}
      </template>

      <template v-slot:table-custom-event-button="{ item }">
        <custom-tooltip
          v-slot="{ on }"
          title="แก้ไข"
        >
          <v-icon
            v-on="on"
            color="info"
            @click="seeDetails(item)"
            class="mx-5"
          >mdi-launch</v-icon>
        </custom-tooltip>
      </template>

    </master-component>
  </div>
</template>


<script>
import { getLocations } from "../js/dropdown-service";
import {
  UserRole,
  InputType,
  DialogSize,
  CreateTeamType,
} from "../js/constants";
import { sumList } from "../js/custom";

export default {
  data() {
    return {
      config: {},
      totalCC: 0,
    };
  },
  created() {
    this.initialConfig();
  },
  methods: {
    initialConfig() {
      this.config = {
        name: "master-team",
        shortName: "ทีม",
        search: this.searchSection,
        form: this.formSection,
        table: this.tableSection,
        permission: this.permission,
        url: this.urlSection,
      };
    },
    seeDetails(item, index) {
      let routeData = this.$router.resolve({
        name: "team-details",
        params: {
          teamId: item.id,
        },
      });
      window.open(routeData.href, "_blank");
    },
    sumTotalCC(items) {
      this.totalCC = sumList(items, "toTalBloodCC");
    },
    calTime(model, val) {
      let url = `/team/calTime?begin=${val}`;
      this.$http.post(url).then((res) => {
        model.end = res;
      });
    },
  },
  computed: {
    urlSection() {
      let searchUrl = "/team/search";
      let getUrl = "/team";
      let addUrl = "/team";
      let editUrl = "/team";
      let removeUrl = "/team";

      return {
        search: searchUrl,
        get: getUrl,
        add: addUrl,
        edit: editUrl,
        remove: removeUrl,
      };
    },
    searchSection() {
      return {
        searchOnInit: false,
        rows: [
          [
            {
              name: "name",
              text: "Name",
              type: InputType.text,
              columnClass: "col-12 col-md-3",
            },
            {
              name: "teamCode",
              text: "Code",
              type: InputType.text,
              columnClass: "col-12 col-md-3",
            },
            {
              name: "university",
              text: "สถานศึกษา",
              type: InputType.dropdown,
              data: {
                url: "/dropdown/universities",
              },
              columnClass: "col-12 col-md-6",
            },
            {
              name: "season",
              text: "Season",
              type: InputType.dropdown,
              data: {
                url: "/dropdown/seasons",
              },
              default: {
                id: "182b1e4d-4acb-47d1-824a-eab37af75a48",
              },
              columnClass: "col-12 col-md-6",
            },
          ],
        ],
      };
    },
    permission() {
      return {
        add: [UserRole.ADMIN, UserRole.MENTOR],
        edit: [UserRole.ADMIN, UserRole.MENTOR],
        remove: [],
      };
    },
    tableSection() {
      return {
        columns: [
          {
            name: "name",
            text: "Name",
          },
          {
            name: "teacher.text",
            text: "อาจารย์ที่ปรึกษา",
          },
          {
            name: "mentor.text",
            text: "นักธุรกิจจิตอาสา",
          },
          {
            name: "university.text",
            text: "สถานศึกษา",
          },
          {
            name: "memberCount",
            text: "จำนวนสมาชิก",
          },
          {
            name: "activityCount",
            text: "จำนวนกิจกรรม",
          },
          {
            name: "toTalBloodCC",
            text: "จำนวนโลหิต",
            numeric: 0,
          },
          {
            name: "code",
            text: "Code",
          },
        ],
      };
    },
    formSection() {
      return {
        size: DialogSize.fullScreen,
        rows: [
          [
            {
              name: "begin",
              text: "ตั้งแต่",
              type: InputType.datePicker,
              disabledIf: (context) => {
                if (!context.model.begin) {
                  context.model.end = null;
                } else {
                  this.calTime(context.model, context.model.begin);
                }

                return false;
              },
            },
            {
              name: "end",
              text: "จนถึง",
              type: InputType.datePicker,
              disabled: true,
            },
            // {
            //   name: "date",
            //   text: "",
            //   type: InputType.begin_end_date,
            //   rule: {},
            //   default: {
            //     begin: "",
            //     end: "",
            //   },
            //   columnClass: "col-12 col-md-4",
            //   disabledIf: (context) => {
            //     er(context.model);

            //     return true;
            //   },
            // },
            {
              name: "createTeamType",
              text: "ประเภททีม",
              type: InputType.dropdown,
              data: {
                url: "/dropdown/CreateTeamTypes",
              },
              rule: {
                required: true,
              },
              columnClass: "col-12 col-md-4",
            },
            {
              name: "sourceTeam",
              text: "อ้างอิงจากทีมเดิม",
              type: InputType.dropdown,
              data: {
                url: "/dropdown/Teams",
              },
              showIf: ({ model }) => {
                let result = false;
                if (
                  model.createTeamType &&
                  model.createTeamType.id == CreateTeamType.EXISTING
                ) {
                  return true;
                }
                return false;
              },
              rule: {
                required: true,
              },
              columnClass: "col-12 col-md-4",
            },
          ],
          [
            {
              name: "name",
              text: "ชื่อทีม",
              type: InputType.text,
              rule: {
                required: true,
              },
              columnClass: "col-12 col-md-4",
            },
            {
              name: "mentor",
              text: "นักธุรกิจจิตอาสา",
              type: InputType.dropdown,
              data: {
                url: "/dropdown/mentors",
              },
              rule: {
                required: true,
              },
              columnClass: "col-12 col-md-4",
            },
            {
              name: "teacher",
              text: "อาจารย์ที่ปรึกษา",
              type: InputType.dropdown,
              data: {
                url: "/dropdown/teachers",
              },
              rule: {
                required: true,
              },
              columnClass: "col-12 col-md-4",
            },
            {
              type: InputType.offset,
            },
          ],
          [
            {
              name: "university",
              text: "สถานศึกษา",
              type: InputType.dropdown,
              data: {
                url: "/dropdown/universities",
              },
              rule: {
                required: true,
              },
              columnClass: "col-12 col-md-8",
            },
          ],
        ],
      };
    },
  },
};
</script>
